body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.clickevent{
  display: flex;
  justify-content: center;
}

.clickevent button{
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: #D0604C solid 1px;
background-color: #F2E0D2;
color: #D0604C;
}

.clickevent .add{
text-align: center;
border: #D0604C solid 1px;
background-color: #F2E0D2;
color: #D0604C;
border-radius: 10px;
width: 120px;
height: 30px;
}

.navbar{
  font-size: 2em;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  background-color: #D0604C;

}

.navbar a:link, .navbar a:visited {
    text-decoration: none;
  padding: 20px;
  color: #F2E0D2; 
}

.navbar a:hover, .navbar a:active {
color: #000; 
}


h1{
  margin: 10px 0 5px 0;
  text-align: center;
  color: #D0604C;
}

/* detail */

.detailCon{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  width: 99%;
}

.detailCol{
margin: 20px;
}

.detaillink{
  text-align: center;
}

/* checkout */
a:link ,a:visited {
  text-decoration: none;
  color: #000; 
}

a:hover,  a:active {
  color: #D0604C; 
  }
  


.checkoutWhole{

}

.checkoutcontainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.left{
  width: 60%;
  text-align: center;
}

.right{
  width: 39%;
  align-self: baseline;
  margin-top: 40px;
}

.right button{
  text-align: center;
  border: #D0604C solid 1px;
  background-color: #F2E0D2;
  color: #D0604C;
  border-radius: 10px;
  width: 120px;
  height: 30px;
}

.eachProduct{
  border: #fff solid 20px;
}

.checkoutCon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkoutRow{
  margin: 10px;
}



/* 紅
#D0604C
#F2E0D2
粉

#000000
#14213D
#FCA311
#E5E5E5
#FFFFFF 
灰白
*/


